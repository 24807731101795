import moment from 'moment';
import _ from 'lodash';
import { TAX_TYPES } from '../constants';

export function formatDate(date) {
  const dateFormat = "YYYY-MM-DD"
  const toDate = new Date(date)
  return moment(toDate).format(dateFormat)
}

export function formatDateOnly(date) {
  const dateFormat = "YYYY-MM-DD"
  return moment(date).format(dateFormat)
}

export function formatCurrency(number) {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  });

  return formatter.format(number)
}

export function formatPhoneNumber(number) {
  var cleaned = ('' + number).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export const dateDisplayFormat = (date) => {
  return date !== '0001-01-01' ? formatDateOnly(date) : 'Unavailable';
} 

export const formatPostalCode = (value) => {
  // Add a space after the third character
  return value.replace(/(\w{3})(\w{3})/, '$1 $2').toUpperCase();
};

const handlePostalCodeChangeOld = (e) => {
  const formattedValue = formatPostalCode(e.target.value.replace(/\s+/g, '')); // Remove existing spaces
  e.target.value = formattedValue;
};


export const handlePostalCodeChange = (e) => {
  const input = e.target;
  const rawValue = input.value.replace(/\s+/g, ''); // Remove all spaces
  const originalValue = input.value;
  const { selectionStart } = input;

  // Calculate the caret offset in the unformatted string
  let caretOffset = selectionStart;
  for (let i = 0; i < selectionStart; i++) {
    if (originalValue[i] === ' ') {
      caretOffset--;
    }
  }

  // Format the value and enforce the 7-character limit
  let formattedValue = formatPostalCode(rawValue).slice(0, 7);
  input.value = formattedValue;

  // Recalculate the caret position
  let newCaretPosition = 0;
  let rawIndex = 0;

  for (let i = 0; i < formattedValue.length; i++) {
    if (formattedValue[i] !== ' ') {
      if (rawIndex === caretOffset) {
        newCaretPosition = i;
        break;
      }
      rawIndex++;
    }
  }

  // Handle edge cases (e.g., typing the first character)
  if (caretOffset >= rawValue.length) {
    newCaretPosition = formattedValue.length;
  }

  // Restore the caret position
  input.setSelectionRange(newCaretPosition, newCaretPosition);
};


export const isNonEmptyString = (value) => typeof(value) == 'string' && value.length > 0

export const accountTypeValueGetter = (params) => {
  const obj = {
    1:"Expense",
    2:"Labour Item",
    3:"Product Cost"
  }
  return obj[params.data['Account Type']]
}

export const currencyTypeValueGetter = (params) => {
  const obj = {
    1:"Canada",
    2:"US",
  }
  return obj[params.data['Currency Type']]
}

export const reportTypeRenderer = (report) => {
  const obj = {
    1:"Quote",
    2:"Invoice",
    3:"Payroll Run",
    4:"Pay Stub",
    5:"Expense",
    6:"Expense Run",
    7:"Payment",
    8:"Payment Run",
  }
  return obj[report]
}

export const defaultTaxCodeValueGetter = (params) => {
  const obj = {
    0:"None",
    1:"GST",
    2:"PST",
    3:"HST",
    4:"GST/PST",
    5:"OTHER",
  }
  return obj[params.data['Default Tax Code']]
}

export const removeDollar = (value) => {
  return value !== undefined ? value.replace(/[$,]/g, '') : "";
}

export const removePercentage = (value) => {
  return value !== undefined ? value.replace(/[%,]/g, '') : "";
}

export const removeDays = (value) => {
  return value !== undefined ? value.replace(/[a-zA-Z\s]/g, '') : "";
}

export function formatTaxType(value) {
  const taxType = _.find(TAX_TYPES, { 'value': value });
  return _.get(taxType, 'label', 'None');
}

export function removePhoneFormat(value) {
  return value !== undefined ? value.replace(/[^\d]/g, '') : "";
}

export function removeNumberFormat(value) {
  if(isNaN(value) && typeof value === "string") {
    return value !== undefined ? value.replace(/[^0-9.]+/g, '') : "";
  } else {
    return value
  }
}

export function removeNonDigits(value) {
  if (_.isNil(value)) {
    return null;
  }

  return value.replace(/\D/g, '');
}

export const formatTaxRate = (value) => {
  return Math.round(100000*value)/1000;
}

export const formTitleSelector = (value) => {
  var title = value

  if(value === "gl-code") {
    title = "GL code";
  }

  if(value === "product-material") {
    title = "product material";
  }

  if(value === "expense-item") {
    title = "expense item";
  }

  if(value === "payroll-run") {
    title = "payroll run";
  }

  return title;

}

export function formatLabel(value, list) {
  const taxType = _.find(list, { 'value': value });
  return _.get(taxType, 'label', 'None');
}

export const formatEllipsis = (params, maxLength) => {
  const value = params.value;
  if(value !== null && value !== undefined) {
    return value.length > maxLength  ? value.substring(0, maxLength) + '...' : value;
  }
  return value
}

export const enumValueFormatter = (params, options) => {
  const statusObject = options.find((item) => item.value === params.value);

  if (statusObject) {
    return statusObject.label;
  } else {
    return "None";
  }
};